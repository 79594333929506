import { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useSanity } from "./useSanity"

const query = graphql`
  query ARTICLES {
    articles: allSanityArticle(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id: _id
          author: _rawAuthor(resolveReferences: { maxDepth: 10 })
          categories: _rawCategories(resolveReferences: { maxDepth: 10 })
          rawContent: _rawContent(resolveReferences: { maxDepth: 10 })
          slug: _rawSlug(resolveReferences: { maxDepth: 10 })
          title: _rawTitle(resolveReferences: { maxDepth: 10 })
          publishedAt
          featuredImage: _rawFeaturedImage(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  }
`

export const useArticles = () => {
  const data = useStaticQuery(query)
  const { rawContentNormaliser, textNormaliser, richTextNormaliser } = useSanity()

  const articles =
    data?.articles?.edges?.length > 0
      ? data.articles.edges
          .filter(({ node }) => node.publishedAt)
          .map(({ node }) => ({
            ...node,
            type: "article",
            content: rawContentNormaliser(node),
            title: textNormaliser(node.title),
            titleText: richTextNormaliser(node.title),
            categories: node?.categories?.length > 0 ? node.categories.map(category => category.title) : [],
          }))
      : []
  const [filteredArticles, setFilteredArticles] = useState(articles)
  const [filters, setFilters] = useState(null)

  const handleFilters = value => {
    setFilters(value)
  }

  const handleResetFilters = () => {
    setFilters(null)
  }

  const availableCategories = []
  articles.map(article =>
    article.categories.map(category => {
      if (!availableCategories.includes(category)) {
        availableCategories.push(category)
      }

      return null
    })
  )

  useEffect(() => {
    const filteredArticles = articles.filter(article => article.categories.includes(filters) || !filters)
    setFilteredArticles(filteredArticles)
  }, [filters])

  return {
    filteredArticles,
    handleResetFilters,
    availableCategories: availableCategories.sort(),
    filters,
    handleFilters,
  }
}
