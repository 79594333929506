import React from "react"
import Masonry from "react-masonry-component"

import { withArticlesGrid } from "./withArticlesGrid"
import { ArticleItem } from "../Item/ArticleItem"
import { GridContainer } from "../ArticleStyles"

interface Props {
  articles: Array<any>
}

export const ArticleGrid = withArticlesGrid(({ articles }: Props) => (
  <GridContainer>
    <Masonry>
      {articles.map(article => (
        <ArticleItem key={article.id} article={article} ratio={article.ratio} ratioMobile={article.ratioMobile} />
      ))}
    </Masonry>
  </GridContainer>
))
