import React from "react"

export const withArticlesGrid = Component => ({ name = `ArticlesGrid`, items, storesPage }) => {
  const articles = items.map((item, i) => {
    const index = i % 6
    const indexMobile = i % 2

    return {
      ...item,
      ratio: index === 0 || index === 3 ? 0.7692 : index === 2 || index === 5 ? 0.909 : 0.5892,
      ratioMobile: !indexMobile ? 0.9166 : 0.7777,
    }
  })

  Component.displayName = name
  return <Component articles={articles} storesPage={storesPage} />
}
