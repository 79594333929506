import React from "react"

import { withArticles } from "./withArticles"
import { HeroBanner } from "../Sections/HeroBanner/HeroBanner"
import { RichText } from "../RichText/RichText"
import { ArticlesFilters } from "./Filters/ArticlesFilters"
import { ArticleGrid } from "./Grid/ArticleGrid"
import { StyledContainer, StyledH2, SeoDescription } from "../Articles/ArticleStyles"

interface Props {
  articles: Array<any>
  categories: Array<any>
  content: any
  filters: any
  handleFilters: Function
  handleResetFilters: Function
  hero: any
}

const OptionalSeoDescription = ({ label, as }: { label: string; as: string }): ReactElement =>
  label ? <SeoDescription as={as}>{`${label}`}</SeoDescription> : null

export const Articles = withArticles(({ articles, categories, content, filters, handleFilters, handleResetFilters, hero }: Props) => (
  <>
    <HeroBanner section={hero} condensed={`true`} gradient={`true`} />
    <StyledContainer>
      <StyledH2>
        <RichText content={content.title}>{typeof content.title === `string` && content.title}</RichText>
      </StyledH2>
      <OptionalSeoDescription as={`p`} label={content.description} />
    </StyledContainer>
    <ArticlesFilters categories={categories} filters={filters} handleFilters={handleFilters} handleResetFilters={handleResetFilters} />
    <ArticleGrid items={articles} storesPage />
  </>
))
