import React from "react"

import { useApp } from "@hooks/useApp"
import { useLanguage } from "@hooks/useLanguage"

export const withArticlesFilters = Component => ({ name = `ArticlesFilters`, categories, filters, handleFilters, handleResetFilters }) => {
  const { invertedTheme } = useApp()
  const locales = {
    ...useLanguage(`accessibility`),
    ...useLanguage(`blog`),
  }

  Component.displayName = name
  return (
    <Component
      categories={categories}
      filters={filters}
      handleFilters={handleFilters}
      handleResetFilters={handleResetFilters}
      invertedTheme={invertedTheme}
      locales={locales}
    />
  )
}
