import React from "react"

import { Wrapper, Icon, IconAlt, Input } from "./SelectStyles"

interface Props {
  altIcon: boolean
  children: any
  minimal: boolean
  name: string
  nativeSelect: boolean
  noIcon: boolean
  onChange: any
  translate: boolean
  underline: boolean
  value: string
  dir: string
  rightAligned: boolean
  leftAligned: boolean
  uppercase: boolean
  blur: boolean
  componentId: string
}

export const Select = ({
  children,
  altIcon = false,
  minimal,
  name,
  nativeSelect,
  noIcon,
  onChange,
  translate,
  underline,
  value,
  rightAligned,
  leftAligned,
  uppercase,
  dir,
  blur,
  componentId,
}: Props) => (
  <Wrapper nativeSelect={nativeSelect ? `true` : null} translate={translate}>
    <Input
      id={componentId}
      altIcon={altIcon}
      minimal={minimal ? `true` : null}
      name={name ? name : null}
      nativeSelect={nativeSelect ? `true` : null}
      onChange={onChange ? event => onChange(event) : null}
      underline={underline}
      value={value}
      rightAligned={rightAligned}
      leftAligned={leftAligned}
      uppercase={uppercase}
      dir={dir || "rtl"}
      blur={blur}
    >
      {children}
    </Input>

    {!noIcon ? altIcon || nativeSelect ? <IconAlt nativeSelect={nativeSelect} /> : <Icon /> : null}
  </Wrapper>
)
