import React, { Fragment } from "react"

import { withArticlesFilters } from "./withArticlesFilters"
import { Select } from "../../Styled/Select/Select"
import { FiltersWrapper, Filters, FilterColumn, FilterText, Filter, FiltersBorder } from "../ArticleStyles"

interface Props {
  categories: Array<any>
  filters: string
  handleFilters: Function
  handleResetFilters: Function
  invertedTheme: boolean
  locales: any
}

export const ArticlesFilters = withArticlesFilters(({ categories, filters, handleFilters, handleResetFilters, invertedTheme, locales }: Props) => (
  <FiltersWrapper>
    <Filters inverted={invertedTheme}>
      <FiltersBorder />
      <FilterColumn>
        <FilterText>{`${locales.filter} ${filters ? filters : locales.all}`}</FilterText>
      </FilterColumn>

      <FilterColumn center={`true`} desktop>
        <FilterText grey={`true`}>{`${locales.sortby}`}</FilterText>
        <Filter active={!filters} inverted={invertedTheme} onClick={() => handleResetFilters()}>
          {locales.all}
        </Filter>

        {categories.map(vendor => (
          <Fragment key={vendor}>
            <Filter key={vendor} active={filters === vendor} onClick={() => handleFilters(vendor)} inverted={invertedTheme}>
              {vendor}
            </Filter>
          </Fragment>
        ))}
      </FilterColumn>

      <FilterColumn end={`true`} mobile>
        <Select
          altIcon
          onChange={event => (event.target.value !== `` ? handleFilters(event.target.value) : handleResetFilters())}
          value={filters ? filters : ``}
          rightAligned
          uppercase
        >
          <option value={``}>{locales.sortby}</option>
          <option value={``}>{locales.all}</option>

          {categories.map(vendor => (
            <option key={vendor} value={vendor}>
              {vendor}
            </option>
          ))}
        </Select>
      </FilterColumn>

      <FilterColumn end={`true`} desktop></FilterColumn>
    </Filters>
  </FiltersWrapper>
))
